.introduction {
  position: relative;
  background: #101720;
  padding: 0 16px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ::selection {
    background-color: #0035ff;
  }
  h2 {
    font-size: clamp(16px, 3vw, 32px);
    font-family: "Satoshi-Medium";
    text-align: center;
    margin-bottom: 16px;
    color: #0c50ff;
    span {
      font-size: clamp(32px, 8vw, 64px);
      font-family: "Bodoni-BoldItalic";
      line-height: 1.2;
      color: #fff;
      background: none;
    }
  }
  .line {
    border-top: 1px solid #f6f6f6;
    margin: 16px 0;
  }
  p {
    opacity: 1;
    margin-bottom: 0;
    text-align: justify;
    font-size: clamp(14px, 1.5vw, 18px);
  }

  .application-desktop {
    display: none;
  }

  ul {
    position: absolute;
    bottom: 32px;
    margin-top: 16px;
    display: flex;
    li {
      margin: 0 10px;
      font-size: clamp(16px, 2vw, 20px);
    }
  }
}

@media (min-width: 768px) {
  .introduction {
    .text-introduction {
      display: flex;
      width: 90%;
    }
    p {
      text-align: justify;
      margin: 0;
    }
    .line {
      border-top: 0;
      border-left: 1px solid #f6f6f6;
      margin: 0 16px;
      width: 0;
    }

    .application-phone {
      display: none;
    }
    .application-desktop {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    ul {
      li {
        font-size: clamp(14px, 2vw, 20px);
      }
    }
  }
}

@media (min-width: 1024px) {
  .introduction {
    padding : 0 32px;
  }
}

@media (min-width: 1440px) {
  .introduction {
    p {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
