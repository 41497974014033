.presentation {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100svh;
  padding-bottom: 32px;
}

.title {
  position: relative;
  width: 100%;
}

.bg-title {
  position: absolute;
  bottom: 0;
  width: 100%;
}

h1 {
  z-index: 1;
  width: 100%;
  font-family: "Satoshi-Medium";
  font-size: clamp(27px, 4.5vw, 100px);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding: 0 10px 0 16px;
  line-height: 110%;
}

span {
  font-family: "Bodoni-Italic";
}

.link-form {
  display: inline-block;
  font-family: "Satoshi-Medium";
  font-size: clamp(14px, 2.5vw, 18px);
  background: #0038ff;
  padding: 12px 32px;
  border-radius: 50px;
  margin-left: 16px;
  color: #fff;
}

.link-social-container {
  position: absolute;
  bottom: 0;
  padding: 32px 16px;
  right: 0;
}

.link-social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
  z-index: 600;
  font-family : "Satoshi-Medium";
  font-size: clamp(14px, 2.5vw, 18px);
}

.presentation {
  background: url("../../assets/images/vision-epurevision.webp");
  background-repeat: no-repeat;
  opacity: 1;
  background-size: cover;
  background-position: 35% 20%;
}

@media (min-width: 768px) {
  .presentation {
    background-position: 20%;
  }
}

@media (min-width: 1024px) {
  .presentation {
    background-position: 0% 30%;
    h1 {
      padding: 0 10px 0 32px !important;
    }
    a.link-form {
      margin-left: 32px;
    }
  }
  .link-social-container {
    padding: 32px;
  }
}

@media (min-width: 1440px) {
  .presentation {
    background-size: cover;
    background-position: 0% 50%;
  }
}
