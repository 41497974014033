@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url("../fonts/Satoshi-Regular.woff2");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "Satoshi-Light";
  src: local("Satoshi-Light"), url("../fonts/Satoshi-Light.woff2");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: local("Satoshi-Medium"), url("../fonts/Satoshi-Medium.woff2");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "Satoshi-Bold";
  src: local("Satoshi-Bold"), url("../fonts/Satoshi-Bold.woff2");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "Bodoni";
  src: local("Bodoni"), url("../fonts/LibreBodoni-Regular.woff2");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "Bodoni-Italic";
  src: local("Bodoni-Italic"), url("../fonts/LibreBodoni-Italic.woff2");
}

@font-face {
  font-family: "Bodoni-Medium";
  src: local("Bodoni-Medium"), url("../fonts/LibreBodoni-Medium.woff2");
}

@font-face {
  font-family: "Bodoni-BoldItalic";
  src: local("Bodoni-BoldItalic"), url("../fonts/LibreBodoni-BoldItalic.woff2");
  font-display: swap;
}
