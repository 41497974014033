.productivite {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: "Bodoni-BoldItalic";
    text-transform : uppercase;
    font-size: clamp(24px, 4vw, 64px);
    margin-bottom: 20px;
    z-index: 1;
  }

  .container-details {
    width: 100%;
    z-index: 1;
  }

  .details {
    ::selection {
      background: #121212;
      color: #f6f6f6;
    }
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    background: #efefef;
    &:not(:nth-child(2)) {
      margin-bottom: 20px;
    }
    .title-details {
      display: flex;
      svg {
        path {
          stroke: #0c50ff;
        }
        circle, rect {
          color : #0c50ff;
        }
      }
    }
    h3 {
      font-family: "Bodoni-BoldItalic";
      font-size: clamp(24px, 3vw, 64px);
      color: #121212;
      margin-right: 10px;
      align-items: baseline;
    }
    p {
      margin-bottom: 10px;
      color: #121212;
      font-family: "Satoshi";
      font-size: clamp(14px, 2.5vw, 18px);
      margin-top: -5px;
      opacity: 80%;
    }
    ul {
      li {
        font-family: "Satoshi-Medium";
        color: #121212;
        font-size: clamp(16px, 2.5vw, 20px);
        margin-bottom: 2px;
      }
    }
  }
}

@media (min-width: 768px) {
  .productivite {
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 35px;
    }
    .container-details {
      display: flex;
    }
    .details:nth-child(1) {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1024px) {
  .productivite {
    padding : 0 32px;
    .container-details {
      justify-content: center;
    }
    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 500px;
      padding: 35px 25px;
    }
  }
}
