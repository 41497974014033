*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// html,
// body,
// div,
// span,
// applet,
// object,
// iframe,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// blockquote,
// pre,
// a,
// abbr,
// acronym,
// address,
// big,
// cite,
// code,
// del,
// dfn,
// em,
// img,
// ins,
// kbd,
// q,
// s,
// samp,
// small,
// strike,
// strong,
// sub,
// sup,
// tt,
// var,
// b,
// u,
// i,
// center,
// dl,
// dt,
// dd,
// ol,
// ul,
// li,
// fieldset,
// form,
// label,
// legend,
// table,
// caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
// article,
// aside,
// canvas,
// details,
// embed,
// figure,
// figcaption,
// footer,
// header,
// hgroup,
// menu,
// nav,
// output,
// ruby,
// section,
// summary,
// time,
// mark,
// audio,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
//   color: #f6f6f6;
// }

body {
  background: #101720;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Satoshi";
}

body,
header,
main,
footer,
.main-container {
  width: 100%;
  height: 100%;
}

li,
a,
p {
  color: #fff;
}

:where(html) {
  box-sizing: border-box;
}

:where(h1, h2, h3, h4, h5, h6) {
  font-weight: 500;
  font-family: "Medium";
  color: #fff;
}

a {
  text-decoration: none;
}

:where(ul) {
  list-style: none;
}

:where(img, video) {
  height: auto;
  max-width: 100%;
}

:where(iframe) {
  border: 0;
}

:where(table) {
  border-collapse: collapse;
  border-spacing: 0;
}

@media (min-width: 768px) {
  :where(html) {
    box-sizing: border-box;
    overflow-x: hidden;
  }
}

::selection {
  background: #101720;
}
