.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #0038ff;
  background: #121212;
  transition: 800ms ease-in;

  .logo-animation-desktop,
  .logo-animation-phone {
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: center;
  }

  .logo-animation-desktop {
    display: none;
  }

  .loader {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 95%;
  }
}

.absolute {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.home-container {
  ::selection {
    background-color: #0038ff;
  }
  height: 100%;
  width: 100%;
  background: #121212;
}

@media (min-width: 768px) {
  .loader-wrapper {
    .loader {
      width: 98.5%;
    }

    .logo-animation-desktop {
      display: block;
    }

    .logo-animation-phone {
      display: none;
    }
  }
}
