.work-section {
  padding: 0 16px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .container-work {
    width: 100%;
  }
  .container-work-phone {
    width: 100%;
  }

  .container-work-desktop {
    display: none;
  }

  h2 {
    font-family: "Bodoni-BoldItalic";
    margin-bottom: 16px;
    font-size: clamp(27px, 4vw, 64px);
  }
  ul {
    border-bottom: 1px solid #f6f6f6;
    width: 100%;
    li {
      width: 100%;
      border-top: 1px solid #f6f6f6;
      padding: 10px 0;
      font-size: clamp(14px, 3vw, 24px);
      a {
        width: 100%;
        padding: 10px 0;
      }
    }
  }
}

.other-work a,
.other-work p {
  color: #0c50ff;
  font-family: "Satoshi-Medium";
  font-size: clamp(14px, 3vw, 24px);
}

@media (min-width: 768px) {
  .work-section {
    margin: 100px 0;
    height: auto;
    display: block;
    .container-work-phone {
      display: none;
    }

    .container-work-desktop {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      img {
        object-fit: cover;
        height: 300px;
        width: 100%;
        mix-blend-mode: luminosity;
        opacity: 1;
        transition: opacity 0.3s ease;
        &:hover {
          mix-blend-mode: normal;
        }
      }

      .figcaption {
        border: none;
        display: flex;
        justify-content: space-between;
        h3 {
          opacity: 0.8;
          font-size: clamp(14px, 2vw, 16px);
          font-family: "Satoshi-Medium";
        }
        p {
          opacity: 0.8;
        }
      }
    }
    .other-work {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: 972.22px) {
  .work-section {
    margin: 100px 0 0 0;
  }
  .container-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
}

@media (min-width: 1024px) {
  .work-section {
    padding : 0 32px;
  }
}

@media (min-width: 1440px) {
  .work-section {
    .container-work-desktop {
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    }
  }
}

@media (min-width: 992px) {
  .other-work a:hover,
  .other-work p:hover {
    color: #fff;
  }
}
