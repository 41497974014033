#header {
  width: 100%;
  position: sticky;
  z-index: 1000;
}

.div-header {
  position: fixed;
  z-index: 1000;
}

nav {
  position: fixed;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  mix-blend-mode: difference;
}

.logo svg {
  position: fixed;
  z-index: 100;
  top: 10px;
  left: 16px;
  height: 50px;
  mix-blend-mode: difference;
  fill: #fff;
}

.menu {
  font-family: "Satoshi";
  font-size: 18px;
  background: none;
  color: #f6f6f6;
  border: none;
  mix-blend-mode: difference;
}

.menu-toggle-desktop,
.navigation-desktop {
  display: none;
}

.open-menu {
  overflow: hidden;
}

.hamburger-menu {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background-color: #0038ff;
  z-index: 1000;
  mix-blend-mode: difference;
}

.menu-icon {
  position: fixed;
  display: flex;
  top: 20px;
  right: 16px;
  cursor: pointer;
}

.hamburger-menu.open {
  right: 0;
  top: 0;
  width: 100%; /* Affiché lorsque le menu est ouvert */
  mix-blend-mode: inherit;
  .logo svg {
    mix-blend-mode: inherit;
  }
}

.menu-toggle {
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: fixed;
  right: 65px;
  top: 20px;
  z-index: 1000;
  mix-blend-mode: difference;
}

.menu-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100svh;
  justify-content: center;
  align-items: center;
  ul {
    text-align: center;
    li {
      font-family: "Satoshi-Medium";
      a {
        font-size: 80px;
        color: #fff;
      }
    }
  }
}

.btn-contact {
  font-size: 18px;
  font-family : "Satoshi-Medium";
  color: #0038FF;
  background : #fff;
  padding : 12px 32px;
  border-radius: 50px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  #header {
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 1000;
  .navigation-desktop {
    padding: 16px;
  }

  .logo svg {
    position: relative;
    height: 60px;
    left: 0;
    top: 0;
  }

  .hamburger-menu {
    display: none;
  }

  .btn-contact {
    bottom: 0;
    z-index: 100;
    position: relative;
    width: auto;
  }
    .navigation-desktop {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      mix-blend-mode: difference;
      z-index: 1000;
      top: 0;

      ul {
        display: flex;
      }

      ul li a {
        color: white;
        margin: 10px;
        font-family: "Satoshi";
        font-size: clamp(16px, 2vw, 1.3rem);
      }
    }
  }

  .link-page {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  #header {
    .navigation-desktop {
      padding: 16px 32px;
    }
  }
}